import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { format } from 'date-fns';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';

import SecondaryPageLayout from '../../components/secondary-page-layout';
import Meta from '../../components/meta';
import FollowUs from '../../components/follow-us';
import * as Text from '../../localisation';
import { Url } from '../../url';

import * as classes from './index.module.css';

type BlogPostCardProps = {
  picture: any,
  title: string,
  date: Date,
  timeToRead: string,
  category: { title: string },
  intro: string,
  url: string,
};

function BlogPostCard(props: BlogPostCardProps) {
  const {
    url,
    picture,
    title,
    date,
    timeToRead,
    category,
    intro,
  } = props;

  return (
    <div className={classes.post}>
      <div>
        <Link to={`/blog/${url}`}>
          <GatsbyImage
            className={classes.postPicture}
            image={getImage(picture?.localFile)}
            alt={picture?.alternativeText}
          />
        </Link>
      </div>

      <h2 className={classes.postTitle}>
        <Link to={`/blog/${url}/`}>{title}</Link>
      </h2>

      <div
        className={classes.postIntro}
        dangerouslySetInnerHTML={{ __html: intro }}
      />

      <div className={classes.postAttributes}>
        <div className={classes.postAttributesLine}>
          <div className={`${classes.postAttribute}`}>
            {category.title}
          </div>
        </div>

        <div className={classes.postAttributesLine}>
          <div className={classes.postAttribute}>
            <CalendarMonthOutlinedIcon />
            {format(new Date(date), 'MMMM d, yyyy')}
          </div>
          <div className={classes.postAttribute}>
            <AvTimerOutlinedIcon />
            {timeToRead}
          </div>
        </div>
      </div>
    </div>
  )
}

function BlogMainPage({ data }: { data: any }) {
  const posts = data.allStrapiArticle.nodes;

  return (
    <SecondaryPageLayout
      pageClassName={classes.page}
    >
      <Meta
        title={Text.BlogPageTitle}
        url={Url.FAQ}
      />

      <h1 className={classes.title}>{Text.BlogTitle}</h1>

      <div className={classes.followUsWrapper}>
        <FollowUs />
      </div>

      <div className={classes.posts}>
        {posts.map((post) => (
          <BlogPostCard
            key={post.id}
            url={post.url}
            picture={post.picture}
            title={post.title}
            date={post.date}
            timeToRead={post.timeToRead}
            category={post.category}
            intro={post.intro.data.childMarkdownRemark.html}
          />
        ))}
      </div>
    </SecondaryPageLayout>
  );
}

export const query = graphql`
  query BlogMainPageQuery {
    allStrapiArticle(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        title
        date
        timeToRead
        url
        category {
          title
        }
        picture {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        intro {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default BlogMainPage;
