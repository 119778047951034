// extracted by mini-css-extract-plugin
export var followUsWrapper = "index-module--followUsWrapper--poPNJ";
export var page = "index-module--page--PGKlf";
export var post = "index-module--post--6IqCk";
export var postAttribute = "index-module--postAttribute--GNlGQ";
export var postAttributes = "index-module--postAttributes--5egYs";
export var postAttributesLine = "index-module--postAttributesLine--WE7SK";
export var postIntro = "index-module--postIntro--LIb9P";
export var postPicture = "index-module--postPicture--Y+oLY";
export var postTitle = "index-module--postTitle--W5th3";
export var posts = "index-module--posts--Mq5lf";
export var title = "index-module--title--HQXIC";